<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon color="teal darken-2" @click="openCreateEmailAudienceContactDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn> -->
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="teal darken-2" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="green--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No {{ plural }} yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item)">
                <v-list-item-content class="text-start">
                    <EmailContactDispatchEventListItem :attr="item"></EmailContactDispatchEventListItem>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="teal darken-2" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="green--text">
                            <Avatar :attr="mainProfile" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item @click="retryDispatch(item)">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'redo']" style="font-size: 20px; color: green;" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="color: green;">Retry</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateEmailContact">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createEmailContact" ref="createEmailContactRef" @submit="validateCreateContact" onSubmit="return false;" @keyup.enter.native="validateCreateContact">
                    <div v-if="add">
                        <v-select :items="addEmailContactChoices" v-model="addEmailContactId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newContactName
                            label="Display Name"
                            :rules="newContactNameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addContact" :disabled="!createEmailContact" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createContact" :disabled="!createEmailContact" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <CreateAudienceContactDialog v-model="createEmailAudienceContactDialogVisible" @cancel="createEmailAudienceContactDialogVisible = false" @created="onCreatedEmailAudienceContact"/>
        <DeleteAudienceContactDialog v-model="redoEmailContactDispatchDialogVisible" @cancel="redoEmailContactDispatchDialogVisible = false" @deleted="onDispatched" :emailAudienceContact="redoEmailContactDispatch"/>
    </v-card>
</template>

<script>
import { toMillis } from '@libertyio/time-util-js';
import EmailContactDispatchEventListItem from '@/components/list-item/EmailContactDispatchEventListItem.vue';
import CreateAudienceContactDialog from '@/components/account-dashboard/CreateAudienceContactDialog.vue';
import DeleteAudienceContactDialog from '@/components/account-dashboard/DeleteAudienceContactDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        EmailContactDispatchEventListItem,
        CreateAudienceContactDialog,
        DeleteAudienceContactDialog,
    },

    props: {
        emailDispatchId: {
            type: String,
            default: null,
            required: false,
        },
        status: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the contact; maybe create a different component for "select a contact"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        createEmailAudienceContactDialogVisible: false,
        redoEmailContactDispatchDialogVisible: false,
        // create contact
        // displayCreateEmailContact: false,
        createEmailContact: null,
        newEmailContactName: null,
        newEmailContactNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Email contact name is required',
        ],
        // add contact
        addEmailContactChoices: [],
        addEmailContactId: null,
        // delete contact
        redoEmailContactDispatch: null, // { email_audience_id, email_contact_id } passed to delete email audience contact dialog when it opens
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        singular() {
            if (typeof this.emailAudienceId === 'string') {
                return 'email contact';
            }
            if (typeof this.emailContactId === 'string') {
                return 'email audience';
            }
            return 'item';
        },
        plural() {
            if (typeof this.emailAudienceId === 'string') {
                return 'email contacts';
            }
            if (typeof this.emailContactId === 'string') {
                return 'email audiences';
            }
            return 'items';
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            // whatever we have (audience id or contact id), we use that to query the other, so the label of the list is the OTHER thing
            if (this.selectOne) {
                return `Select one ${this.singular}`;
            }
            switch (this.list.length) {
            case 0:
                return `No ${this.plural}`;
            case 1:
                return `1 ${this.singular}`;
            default:
                return `${this.list.length} ${this.plural}`;
            }
        },
    },

    watch: {
        emailAudienceId(newValue) {
            if (newValue) {
                this.loadEmailContactDispatchEventList();
            }
        },
        emailContactId(newValue) {
            if (newValue) {
                this.loadEmailContactDispatchEventList();
            }
        },
        // displayCreateEmailContact(value) {
        //     if (value && (this.add || this.create)) {
        //         this.initAddEmailContactChoices();
        //     }
        // },
    },

    methods: {
        // loads either 1) all contacts for an audience, or 2) all audiences for a contact, depending on which view its in
        async loadEmailContactDispatchEventList() {
            try {
                this.$store.commit('loading', { loadEmailContactDispatchEventList: true });
                const match = {};
                if (typeof this.emailDispatchId === 'string') {
                    match.email_dispatch_id = this.emailDispatchId;
                }
                if (typeof this.status === 'string') {
                    match.status = this.status;
                }
                const response = await this.$client.account(this.$route.params.accountId).emailContactDispatchEvent.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadEmailContactDispatchEventList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailContactDispatchEventList: false });
            }
        },
        onClickItem(item) {
            /*
            if (this.selectOne) {
                this.$emit('selected', { emailContactId });
            }
            */
            console.log(`EmailAudienceContactList: clicked item ${JSON.stringify(item)}`);
            this.$emit('selected', item);
        },
        openRedoEmailContactDispatchDialog(item) {
            this.redoEmailContactDispatch = item;
            this.redoEmailContactDispatchDialogVisible = true;
        },
        // async createContact() {
        //     this.error = false;
        //     console.log('createContact');
        //     const request = {
        //         // name: this.name,
        //         name: this.newContactName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if contact arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the contact to an appropriate location after contact is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createContact response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const contact = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', contact);
        //         this.$emit('added-site', contact);
        //         this.displayCreateEmailContact = false;
        //         this.newContactName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateContact() {
        //     if (this.$refs.createEmailContactRef.validate()) {
        //         this.createContact();
        //     }
        // },

        // async addContact() {
        //     const contact = { id: this.addEmailContactId, label: this.addEmailContactChoices.find((item) => item.value === this.addEmailContactId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', contact);
        //     this.displayCreateEmailContact = false;
        //     this.addEmailContactId = null;
        // },
        // async initAddContactChoices() {
        //     // create a map of which contact ids are already in the list
        //     const contactMap = {};
        //     this.list.forEach((item) => {
        //         contactMap[item.id] = true;
        //     });
        //     // when we show the add/create contact dialog, load the list of available contacts to add to the policy (list of all contacts less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`ContactViewList.vue fetch contacts result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove contacts already in the policy, then convert the available contacts to a choice list for the v-select [ { text, value }, ... ]
        //         this.addEmailContactChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
        // NOTE: the retryDispatch function is only for contact dispatch events that have the 'error' status; the server will put the contact back into the queue for that dispatch and try the delivery again
        async retryDispatch(item) {
            try {
                console.log(`retryDispatch item ${JSON.stringify(item)}`);
                this.$store.commit('loading', { retryDispatch: true });
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.check(item.email_dispatch_id, { item: 'retry', email_contact_id: item.email_contact_id });
                console.log(`retryDispatch response: ${JSON.stringify(response)}`);
                setTimeout(() => { this.loadEmailContactDispatchEventList(); }, toMillis({ seconds: 15 })); // TODO: we need a separate indeterminate progress indicator, maybe a circular one, to show up while we wait for the status...
                // TODO: how to get the status of this one dispatch? what if we later have checkboxes and user can select more than one to retry -- then each selected contact should go back into the queue, we should set the status to 'started' again, and get updates. but this isn't the email dispatch view, so we need a timer here for checking up on the status of the retry.
                // if (response.status) {
                //     this.list = response.list;
                // }
            } catch (err) {
                console.error('retryDispatch failed', err);
            } finally {
                this.$store.commit('loading', { retryDispatch: false });
            }
        },
        onDispatched() {
            this.redoEmailContactDispatchDialogVisible = false;
            this.loadEmailContactDispatchEventList(); // TODO: instead of reloading the entire list, just remove the item that was deleted... need to pass it up from the component in the event so we can remove just that one
        },
    },

    mounted() {
        this.loadEmailContactDispatchEventList();
    },
};
</script>
