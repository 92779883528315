<template>
    <div>
        <p>&lt;{{ email }}&gt; ({{ status }})</p>
        <!-- <p class="text-caption">{{ id }}</p> -->
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        // id() {
        //     return this.attr.id;
        // },
        email() {
            return this.attr.email;
        },
        status() {
            return this.attr.status;
        },
        // alias() {
        //     return this.attr.alias;
        // },
    },

};
</script>
